import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import styles from './StyledMenu.module.scss'
import { getIcon } from '../../utils/iconHelper';
import { ICON_TYPE, COLOR } from '../../utils/enums/globalEnums';

export type MenuItemProp = {
    id: any
    text: string
    action: () => void
    isDisabled: boolean
    icon?: ICON_TYPE
    color?: COLOR
}

type Props = {
    text: string
    color: COLOR
    size?: 'small' | 'medium' | 'large'
    fontColor?: COLOR
    icon?: ICON_TYPE
    menuItems: MenuItemProp[]
    child?: JSX.Element
}

const StyledMenu = ({ text, color, size, fontColor, icon, menuItems, child }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const handleActionItemClick = (menuItem: MenuItemProp) => {
    menuItem.action()
    handleClose()
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        //9005 AdatTár: egyből a feltöltés gombra kattintva lehessen kiválasztani a fájlt
        onClick={(e) => handleClick(e)}
      >
        {icon !== undefined ? <Grid ml='-14px' container spacing={0}>
            <Grid item ml={1.5}>
            {getIcon(icon)}
            </Grid>
            <Grid item mt='5px' ml={1.5}>
            {text}
            </Grid>
        </Grid> : child !== undefined ? child : text}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuItems.map(menuItem => 
            !menuItem.isDisabled && <MenuItem key={menuItem.id} onClick={() => handleActionItemClick(menuItem)}>
                {menuItem.icon !== undefined ? <Grid container spacing={2}>
                    <Grid item mt='6px'>
                      {getIcon(menuItem.icon)}
                    </Grid>
                    <Grid item mt='8px' className={styles['menuitem-text']} color={menuItem.color ?? '#525252'}>
                      {menuItem.text}
                    </Grid>
                </Grid> : menuItem.text}
            </MenuItem>
        )}
      </Menu>
    </div>
  );
}

export default StyledMenu