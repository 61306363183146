import { useSelector, useDispatch } from "react-redux";
import StyledModal from "./StyledModal";
import { closeModal } from "../../utils/reducers/rootReducer";
import { ModalModel } from "../../utils/enums/globalEnums";

const Modal = () => {
  const dispatch = useDispatch();
  const isShown = useSelector((state: ModalModel) => state.isShown);
  const modalType = useSelector((state: ModalModel) => state.modalType);
  const handleConfirmedAction = useSelector(
    (state: ModalModel) => state.handleConfirmedAction
  );
  const itemId = useSelector((state: ModalModel) => state.itemId);
  const item = useSelector((state: ModalModel) => state.item);
  const text = useSelector((state: ModalModel) => state.text);
  const description = useSelector((state: ModalModel) => state.description);
  const handleCloseModal = () => dispatch(closeModal(false));

  return (
    <StyledModal
      open={isShown}
      handleClose={handleCloseModal}
      title={text}
      type={modalType}
      handleConfirmedAction={handleConfirmedAction}
      itemId={itemId}
      description={description}
      item={item}
    />
  );
};

export default Modal;
