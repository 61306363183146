import { useContext, useEffect } from "react";
import CompanySelector from "./components/company-selector/CompanySelector";
import { store } from "./providers";
import { UserService, ApiError, B2CCompanyData } from "./UserManagerApi";
import { UserManagerOpenAPI } from "./UserManagerApi/core/OpenAPI";
import {
  COLOR,
  INTERNAL_ROUTE,
  LOCAL_STORAGE_KEY,
} from "./utils/enums/globalEnums";
import { showErrorMessage } from "./utils/reducers/rootReducer";
import {
  CompanyContext,
  SelectedCompanyContext,
} from "./utils/contexts/globalContexts";
import StyledButton from "./components/button/StyledButton";
import { useNavigate } from "react-router-dom";
import { InvitationOpenAPI } from "./InvitationApi";
import Row from "./components/layout/Row";
import { getErrorMessage } from "./utils/helper-functions/HelperFunctions";
import { acquireToken } from "./utils/auth/AuthActions";

export default function Selector() {
  const navigate = useNavigate();
  const { companies, setCompanies } = useContext(CompanyContext);
  const { selectedCompany, setSelectedCompany } = useContext(
    SelectedCompanyContext
  );

  useEffect(() => {
    setApiHeaders();
    const dbGuid = localStorage.getItem(LOCAL_STORAGE_KEY.DB_GUID);
    if (dbGuid) {
      navigate(INTERNAL_ROUTE.USERS);
    } else {
      if (companies?.length === 0) getCompanyList();
    }
  }, []);

  const setApiHeaders = () => {
    UserManagerOpenAPI.TOKEN = async () => (await acquireToken()) as any;

    InvitationOpenAPI.TOKEN = async () => (await acquireToken()) as any;
  };

  const getCompanyList = async () => {
    await UserService.getUsersCompanyList()
      .then(async (res) => {
        if (res !== null || res[0] !== null) {
          setCompanies(res);
          if (res?.length === 1) {
            await changeSelectedCompany(res[0]).then(() => {
              navigate(INTERNAL_ROUTE.USERS);
            });
          }
        } else
          store.dispatch(showErrorMessage(["", "Nem érhetőek el adatok."]));
      })
      .catch((e: ApiError) =>
        store.dispatch(
          showErrorMessage(["Hiba történt", getErrorMessage(e.status)])
        )
      );
  };

  useEffect(() => {
    console.log(UserManagerOpenAPI.HEADERS);
  }, [UserManagerOpenAPI.HEADERS]);

  const changeSelectedCompanyByGuid = async (dbId: string) => {
    const filtered = companies?.filter((dbinfo) => dbinfo.guid === dbId);
    if (filtered[0] !== undefined) changeSelectedCompany(filtered[0]);
  };

  const changeSelectedCompany = async (comp: B2CCompanyData) => {
    await setSelectedCompany(comp);
    localStorage.setItem(LOCAL_STORAGE_KEY.DB_GUID, comp?.guid ?? "");
    UserManagerOpenAPI.HEADERS = { dbguid: comp?.guid ?? "" };
    InvitationOpenAPI.HEADERS = { dbguid: comp?.guid ?? "" };
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <Row>
        <div style={{ fontSize: "3rem", paddingBottom: "70px" }}>
          {"Kulcs-Portál"}
        </div>
      </Row>
      <div style={{ height: "150px" }}>
        <CompanySelector
          text="Válasszon céget"
          companies={companies}
          changeSelectedCompany={changeSelectedCompanyByGuid}
        />
      </div>
      <div>
        <StyledButton
          disabled={!selectedCompany?.guid}
          color={COLOR.DATAPOINT}
          text="Tovább a felhasználókra"
          onClick={() => {
            navigate(INTERNAL_ROUTE.USERS);
          }}
        />
      </div>
    </div>
  );
}
