import styled from "@emotion/styled";
import { TableRow } from "@mui/material";

export const StyledTableRow = styled(TableRow)(() => ({
    '& td': {
      borderTop: '1px solid #ABCAF2',
      borderBottom: '1px solid #ABCAF2'
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));