import { StyledTable } from "../table/styled-components/StyledTable";
import StyledTableHead from "../table/styled-components/StyledTableHead";
import StyledTableBody from "../table/styled-components/StyledTableBody";
import { StyledContainer } from "../table/styled-components/StyledContainer";
import { User } from "../../UserManagerApi";

type Props = {
  users: User[];
  isLoading: boolean;
};

const UserTab = ({ users, isLoading }: Props) => {
  return (
    <StyledContainer>
      <StyledTable stickyHeader size="small">
        <StyledTableHead />
        <StyledTableBody isLoading={isLoading} users={users} />
      </StyledTable>
    </StyledContainer>
  );
};

export default UserTab;
