import { msalInstance } from "./utils/auth/AuthProvider";
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import AuthPage from "./pages/authentication/AuthPage";
import { Providers } from "./providers";

export default function Auth({ children }: { children: React.ReactNode }) {
  return (
    <MsalProvider instance={msalInstance}>
      <Providers>
        <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <AuthPage />
        </UnauthenticatedTemplate>
      </Providers>
    </MsalProvider>
  );
}
