export const getInitialsFromName = (name: string) => {
  return name
    .match(/(^\S\S?|\b\S)?/g)
    ?.join("")
    .match(/(^\S|\S$)?/g)
    ?.join("")
    .toUpperCase();
};

export const getLocalDateString = (date: string | null | undefined) => {
  if (date === null || date === undefined) return "";
  return new Date(date)?.toLocaleString("hu-HU", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};
