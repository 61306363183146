import React from "react";
import { B2CCompanyData, User } from "../../UserManagerApi";
import { SecondModal } from "../enums/globalEnums";

export type DeviceProps = {
  isMobile: boolean;
  setIsMobile: any;
  isMobileLandscape: boolean;
  setIsMobileLandscape: any;
};

export const DeviceContext = React.createContext<DeviceProps>({
  isMobile: false,
  setIsMobile: null,
  isMobileLandscape: false,
  setIsMobileLandscape: null,
});

export type RefreshProps = {
  refresh: boolean;
  setRefresh: any;
};

export const RefreshContext = React.createContext<RefreshProps>({
  refresh: false,
  setRefresh: null,
});

export type UsersProps = {
  users: User[];
  setUsers: any;
};

export const UsersContext = React.createContext<UsersProps>({
  users: [] as User[],
  setUsers: null,
});

export type SecondModalProps = {
  secondModalProps: SecondModal;
  setSecondModalProps: any;
};

export const SecondModalContext = React.createContext<SecondModalProps>({
  secondModalProps: {} as SecondModal,
  setSecondModalProps: null,
});

export type SelectedCompanyProps = {
  selectedCompany: B2CCompanyData;
  setSelectedCompany: any;
};

export const SelectedCompanyContext = React.createContext<SelectedCompanyProps>(
  {
    selectedCompany: {} as B2CCompanyData,
    setSelectedCompany: null,
  }
);

export type CompanyProps = {
  companies: B2CCompanyData[];
  setCompanies: any;
};

export const CompanyContext = React.createContext<CompanyProps>({
  companies: [] as B2CCompanyData[],
  setCompanies: null,
});
