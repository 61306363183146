import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Auth from "./auth";
import Users from "./Users";
import Selector from "./Selector";
import FirstPage from "./FirstPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Auth>
              <Selector />
            </Auth>
          }
        />
        <Route path="/auth" element={<FirstPage />} />
        <Route
          path="/users"
          element={
            <Auth>
              <Users />
            </Auth>
          }
        />
        <Route
          path="/selector"
          element={
            <Auth>
              <Selector />
            </Auth>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
