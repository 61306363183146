import styled from "@emotion/styled";
import TableContainer from "@mui/material/TableContainer";

export const StyledContainer = styled(TableContainer)(() => ({
  ["&::-webkit-scrollbar"]: {
    width: 7,
  },
  ["&::-webkit-scrollbar-track"]: {
    backgroundColor: "#f2f5fa",
  },
  ["&::-webkit-scrollbar-thumb"]: {
    backgroundImage: "linear-gradient(#f2f5fa 42px, #cfdced 39px)",
    borderRadius: 7,
  },
  borderRadius: "10px",
  boxShadow: "5px 10px 10px #ECECEC",
  maxHeight: "calc(100vh - 210px)",
}));
