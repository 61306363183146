import styled from "@emotion/styled";
import { Tab } from "@mui/material";
import { TAB } from "../../../utils/enums/globalEnums";

interface StyledTabProps {
    label: string,
    value: TAB
}

export const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
    () => ({
      textTransform: 'none',
      minWidth: 0,
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '18px',
      color: 'rgba(0, 0, 0, 0.85)',
      fontFamily: [
        'Roboto',
        'sans-serif',
      ].join(','),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&.Mui-selected': {
        color: '#007FFF',
      },
      '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
      },
    }),
  );