import { InvitationStatus, Role } from "../../UserManagerApi";
import { STATUS } from "../enums/globalEnums";

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export function getUserStateEnum(
  state: string | null | undefined
): string | null | undefined {
  switch (state) {
    case "Active":
      return STATUS.ACTIVE;
    case "Inactive":
      return STATUS.INACTIVE;
    case "Invited":
      return STATUS.INVITED;
    default:
      break;
  }
}

export const getEnumTypeText = (enumType: any) => {
  switch (enumType) {
    case Role.ADMIN:
    case "Admin":
      return "Admin";
    case Role.ACCOUNTANT:
    case "Accountant":
      return "Könyvelő";
    case Role.USER:
    case "User":
      return "Felhasználó";
    case Role.GUEST:
    case "Guest":
      return "Vendég";
    case InvitationStatus.ADDED_WITH_PASSWORD:
    case "ADDED_WITH_PASSWORD":
      return "Jelszóval összekötött";
    case InvitationStatus.PENDING:
    case "PENDING":
      return "Függőben";
    case InvitationStatus.DONE:
    case "DONE":
      return "Elfogadott";
    default:
      return "";
  }
};

export const getErrorMessage = (code: number) => {
  switch (code) {
    case 403:
      return "Nincsen jogosultsága a művelethez.";
    default:
      return `${code} - Hiba történt az adatok betöltése során.`;
  }
};
