import { Box, Typography } from "@mui/material";
import { TAB } from "../../utils/enums/globalEnums";

interface TabPanelProps {
  children?: React.ReactNode;
  index: TAB;
  value: TAB;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ paddingTop: "15px" }}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}
