import { TableHead } from "@mui/material";
import i18n from "../../../utils/i18n";
import { StyledTableCell } from "./StyledTableCell";
import { StyledTableRow } from "./StyledTableRow";

const StyledTableHead = () => {
  return (
    <TableHead id="table-header">
      <StyledTableRow>
        <StyledTableCell
          style={{
            width: "20vw",
            lineHeight: "30px",
            backgroundColor: "#f2f5fa",
          }}
          align="left"
        >
          {i18n("mainPage.headers.user")}
        </StyledTableCell>
        {/* <StyledTableCell
          style={{ width: "20vw", backgroundColor: "#f2f5fa" }}
          align="left"
        >
          {i18n("mainPage.headers.objId")}
        </StyledTableCell> */}
        <StyledTableCell
          style={{ width: "20vw", backgroundColor: "#f2f5fa" }}
          align="left"
        >
          {i18n("mainPage.headers.email")}
        </StyledTableCell>
        <StyledTableCell
          style={{ width: "15vw", backgroundColor: "#f2f5fa" }}
          align="left"
        >
          {i18n("mainPage.headers.permission")}
        </StyledTableCell>
        {/* <StyledTableCell width={'150px'} align="left">{i18n('mainPage.headers.programTypes')}</StyledTableCell> */}
        {/* <StyledTableCell style={{ width: "15vw" }} align="left">
              {i18n("mainPage.headers.lastSignInDate")}
            </StyledTableCell>*/}
        <StyledTableCell
          style={{ width: "15vw", backgroundColor: "#f2f5fa" }}
          align="left"
        >
          {i18n("mainPage.headers.status")}
        </StyledTableCell>
        <StyledTableCell style={{ backgroundColor: "#f2f5fa" }} align="right" />
      </StyledTableRow>
    </TableHead>
  );
};

export default StyledTableHead;
