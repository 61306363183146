import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LOCAL_STORAGE_KEY } from "./utils/enums/globalEnums";

export default function FirstPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    getContext().then(() => {
      navigate("/");
    });
  }, []);

  const getContext = async () => {
    const dbGuid = searchParams.get("dbGuid");
    if (dbGuid) localStorage.setItem(LOCAL_STORAGE_KEY.DB_GUID, dbGuid);
  };

  return <div></div>;
}
