import styled from "@emotion/styled";
import { Tabs } from "@mui/material";
import { TAB } from "../../../utils/enums/globalEnums";

interface StyledTabsProps {
    children?: React.ReactNode;
    value: TAB;
    onChange: (event: React.SyntheticEvent, newValue: TAB) => void;
  }

export const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      backgroundColor: 'transparent'
    },
    '& .MuiTabs-indicatorSpan': {
    //   maxWidth: 40,
      width: '100%',
      backgroundColor: '#007FFF',
    },
  });