import { store } from "../../../providers";
import { User, UserService } from "../../../UserManagerApi";
import { ICON_TYPE, COLOR } from "../../../utils/enums/globalEnums";
import { getErrorMessage } from "../../../utils/helper-functions/HelperFunctions";
import {
  showErrorMessage,
  showSuccessMessage,
  showUserDelete,
  showUserEdit,
} from "../../../utils/reducers/rootReducer";
import { MenuItemProp } from "../../menu/StyledMenu";

export const getMenuItems = (user: User, localAccountId: string) =>
  [
    {
      id: 0,
      text: "Szerkesztés",
      action: () =>
        store.dispatch(
          user?.objectId === localAccountId
            ? showErrorMessage([
                "Hiba",
                "A saját felhasználó szerepkörének módosítása nem lehetséges.",
              ])
            : showUserEdit([
                `${
                  user.lastName && user.firstName
                    ? `${user.lastName} ${user.firstName} szerepkörének `
                    : "Szerepkör"
                } szerkesztése`,
                () => handleUserEditClick(user),
                user,
              ])
        ),
      isDisabled: false,
      icon: ICON_TYPE.PEN,
    },
    {
      id: 2,
      text: "Törlés",
      action: () =>
        store.dispatch(
          showUserDelete([
            `${user.lastName} ${user.firstName}`,
            () => handleUserDeleteClick(user, localAccountId),
          ])
        ),
      isDisabled: false,
      icon: ICON_TYPE.TRASH,
      color: COLOR.RED,
    },
  ] as MenuItemProp[];

const placeholderAction = () =>
  store.dispatch(showErrorMessage(["A funkció még nem érhető el"]));

const handleUserDeleteClick = async (user: User, localAccountId: string) => {
  if (user?.objectId === localAccountId) {
    store.dispatch(
      showErrorMessage(["Hiba", "A saját felhasználó törlése nem lehetséges."])
    );
    return;
  }
  await UserService.deleteUsers(user.id!)
    .then((res) => {
      store.dispatch(
        showSuccessMessage([
          "Felhasználó törlése",
          "A felhasználó sikeresen törölve.",
          () => {},
        ])
      );
    })
    .catch((e) => {
      store.dispatch(showErrorMessage(["Hiba", getErrorMessage(e.status)]));
    });
};

const handleUserEditClick = async (user: User) => {
  console.log("user to edit", user);
};
