import { Stylable } from "../../utils/stylable"


interface Props extends Stylable {
	children: React.ReactNode
}

const Row = ({ className, children }: Props) => {
	const applyClasses = () => {
		const defaultJustifyClass = 'flex-around'
		const justifyClassesPrefix = 'flex-'
		if (className) {
			return className.includes(justifyClassesPrefix) ? className : `${defaultJustifyClass}  ${className}`
		}
		return defaultJustifyClass
	}
	return <div className={`row ${applyClasses()}`}>{children}</div>
}

export default Row
