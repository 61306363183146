import { ButtonProps } from "@mui/material/Button";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import styles from "./StyledButton.module.scss";
import { addOpacity } from "../../utils/colorHelper";
import { COLOR, ICON_TYPE } from "../../utils/enums/globalEnums";
import LoadingButton from "@mui/lab/LoadingButton";
import { getIcon } from "../../utils/iconHelper";

type Props = {
  color: COLOR;
  onClick: (ev?: any) => void;
  disabled?: boolean;
  size?: "small" | "medium" | "large";
  fontColor?: COLOR;
  icon?: ICON_TYPE;
  loading?: boolean;
  needContainer?: boolean;
  text?: string;
};

const StyledButton = ({
  text,
  color,
  onClick,
  disabled,
  size,
  fontColor,
  icon,
  loading,
  needContainer,
}: Props) => {
  const ColorButton = styled(LoadingButton)<ButtonProps>(() => ({
    color: disabled ? COLOR.BLACK : fontColor ?? "#FFFFFF",
    minWidth:
      icon === ICON_TYPE.REFRESH || icon === ICON_TYPE.EXIT ? 0 : "160px",
    paddingTop: 0,
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: 0,
    height: "42px",
    backgroundColor: disabled ? COLOR.GRAY : color,
    "&:hover": {
      backgroundColor: addOpacity(color, 0.85),
    },
    textTransform: "none",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
  }));

  const getButton = () => (
    <ColorButton
      className={styles["button"]}
      loading={loading}
      variant="contained"
      size={size}
      disabled={disabled}
      onClick={onClick}
    >
      {icon !== undefined ? (
        <Grid container spacing={2}>
          <Grid item sx={{ mt: "5px" }}>
            {getIcon(icon)}
          </Grid>
          {text && (
            <Grid item alignSelf={"center"}>
              {text}
            </Grid>
          )}
        </Grid>
      ) : (
        text
      )}
    </ColorButton>
  );

  return needContainer ? (
    <div style={{ marginLeft: "auto", marginRight: "10px" }}>{getButton()}</div>
  ) : (
    getButton()
  );
};

export default StyledButton;
