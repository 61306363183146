import styles from "./Header.module.scss";

type Props = {
  children: React.ReactNode;
};

const Header = ({ children }: Props) => {
  return (
    <header className={styles.header}>
      <div style={{}}>{children}</div>
    </header>
  );
};

export default Header;
