import { ICON_TYPE } from "./enums/globalEnums";
import { ReactComponent as PlusIcon } from "../assets/icons/+.svg";
import { ReactComponent as RetryIcon } from "../assets/icons/resend.svg";
import { ReactComponent as PenIcon } from "../assets/icons/pen.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/trash.svg";
import { ReactComponent as OtherIcon } from "../assets/icons/others.svg";
import { ReactComponent as KulcsUzletLogo } from "../assets/logo/kulcsuzlet.svg";
import { ReactComponent as DocuStoreLogo } from "../assets/logo/docustore.svg";
import { ReactComponent as DataPointLogo } from "../assets/logo/datapoint.svg";
import { ReactComponent as RefreshIcon } from "../assets/icons/refresh-icon.svg";
import { ReactComponent as ExitIcon } from "../assets/icons/exit-icon.svg";
import styles from "./iconHelper.module.scss";

export const getIcon = (icon: ICON_TYPE) => {
  switch (icon) {
    case ICON_TYPE.PLUS:
      return <PlusIcon />;
    case ICON_TYPE.RETRY:
      return <RetryIcon />;
    case ICON_TYPE.PEN:
      return <PenIcon />;
    case ICON_TYPE.TRASH:
      return <TrashIcon />;
    case ICON_TYPE.OTHER:
      return (
        <div className={styles["other-icon"]}>
          <OtherIcon />
        </div>
      );
    case ICON_TYPE.DATAPOINT:
      return <DataPointLogo />;
    case ICON_TYPE.DOCUSTORE:
      return <DocuStoreLogo />;
    case ICON_TYPE.KULCSUZLET:
      return <KulcsUzletLogo />;
    case ICON_TYPE.REFRESH:
      return <RefreshIcon style={{ width: "30px", fill: "white" }} />;
    case ICON_TYPE.EXIT:
      return <ExitIcon style={{ width: "30px", fill: "white" }} />;
    default:
      break;
  }
};
