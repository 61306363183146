/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Role {
    ADMIN = 'ADMIN',
    ACCOUNTANT = 'ACCOUNTANT',
    USER = 'USER',
    GUEST = 'GUEST',
}
