import { MODAL_TYPE, ModalModel } from "../enums/globalEnums";

const initState: ModalModel = {
  text: "",
  isShown: false,
  modalType: MODAL_TYPE.NONE,
  itemId: "",
  description: "",
};

export enum ReducerAction {
  CLOSE_MODAL,
  ERROR,
  CONFIRMATION,
  SUCCESS,
  INVITE,
  LOADING,
  DELETE_USER,
  EDIT_USER,
}

type Action<P> = {
  type: ReducerAction;
  payload: P;
};

type ActionCreator<P> = (p: P) => Action<P>;

export const closeModal: ActionCreator<boolean> = (payload) => ({
  type: ReducerAction.CLOSE_MODAL,
  payload,
});

export const showErrorMessage: ActionCreator<string[]> = (payload) => ({
  type: ReducerAction.ERROR,
  payload,
});

export const showConfirmationMessage: ActionCreator<any[]> = (payload) => ({
  type: ReducerAction.CONFIRMATION,
  payload,
});

export const showSuccessMessage: ActionCreator<any[]> = (payload) => ({
  type: ReducerAction.SUCCESS,
  payload,
});

export const showUserInvite: ActionCreator<any[]> = (payload) => ({
  type: ReducerAction.INVITE,
  payload,
});

export const showUserDelete: ActionCreator<any[]> = (payload) => ({
  type: ReducerAction.DELETE_USER,
  payload,
});

export const showUserEdit: ActionCreator<any[]> = (payload) => ({
  type: ReducerAction.EDIT_USER,
  payload,
});

export const showLoading: ActionCreator<string> = (payload) => ({
  type: ReducerAction.LOADING,
  payload,
});

const rootReducer = (state: ModalModel = initState, action: any) => {
  switch (action.type) {
    case ReducerAction.CLOSE_MODAL:
      return {
        text: "",
        modalType: MODAL_TYPE.NONE,
        isShown: false,
      };
    case ReducerAction.LOADING:
      return {
        text: action.payload,
        modalType: MODAL_TYPE.LOADING,
        isShown: true,
      };
    case ReducerAction.ERROR:
      return {
        text: action.payload[0],
        description: action.payload[1],
        modalType: MODAL_TYPE.ERROR,
        isShown: true,
      };
    case ReducerAction.CONFIRMATION:
      return {
        text: action.payload[0],
        description: action.payload[1],
        modalType: MODAL_TYPE.CONFIRMATION,
        handleConfirmedAction: action.payload[2],
        isShown: true,
      };
    case ReducerAction.SUCCESS:
      return {
        text: action.payload[0],
        description: action.payload[1],
        modalType: MODAL_TYPE.SUCCESS,
        isShown: true,
        handleConfirmedAction: action.payload[2],
      };
    case ReducerAction.INVITE:
      return {
        text: action.payload[0],
        description: action.payload[1],
        modalType: MODAL_TYPE.INVITE,
        isShown: true,
        handleConfirmedAction: action.payload[2],
      };
    case ReducerAction.DELETE_USER:
      return {
        text: action.payload[0],
        modalType: MODAL_TYPE.DELETE_USER,
        isShown: true,
        handleConfirmedAction: action.payload[1],
      };
    case ReducerAction.EDIT_USER:
      return {
        text: action.payload[0],
        modalType: MODAL_TYPE.EDIT_USER,
        isShown: true,
        handleConfirmedAction: action.payload[1],
        item: action.payload[2],
      };
  }
  return state;
};

export default rootReducer;
