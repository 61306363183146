import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import styles from "./StyledModal.module.scss";
import i18n from "../../utils/i18n";
import { Grid } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assets/icons/X.svg";
import { useContext, useEffect, useState } from "react";
import StyledButton from "../button/StyledButton";
import { SIZE, COLOR, MODAL_TYPE } from "../../utils/enums/globalEnums";
import { Separator } from "../separator/Separator";
import InviteUserFields from "./InviteUserFields";
import {
  RefreshContext,
  UsersContext,
} from "../../utils/contexts/globalContexts";
import EditUserFields from "./EditUserFields";
import { User } from "../../UserManagerApi";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #ACBBCD",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
  background: "#F2F9FF",
};

type Props = {
  open: boolean;
  handleClose: () => void;
  title: string | string[];
  type: MODAL_TYPE;
  handleConfirmedAction?: () => void;
  itemId?: string;
  description?: string;
  item?: User;
};

const StyledModal = ({
  open,
  handleClose,
  title,
  type,
  handleConfirmedAction,
  itemId,
  description,
  item,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { setRefresh } = useContext(RefreshContext);
  const { users } = useContext(UsersContext);

  useEffect(() => {
    if (!open) setBackStates();
  }, [open]);

  const setBackStates = () => {
    setIsLoading(false);
    setError(null);
  };

  const setLoadingButton = async (loading: boolean) =>
    await setIsLoading(loading);

  const doConfirm = async () => {
    handleConfirmedAction &&
      setLoadingButton(true).then(() => {
        doConfirmedActionAsync().then(() => {
          setRefresh(true);
          setBackStates();
        });
      });
  };

  const doConfirmedActionAsync = async () => {
    handleConfirmedAction && (await handleConfirmedAction());
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
      >
        <Fade in={open} easing={"unmountOnExit"}>
          <Box
            sx={style}
            minWidth={type === MODAL_TYPE.INVITE ? "30vw" : 400}
            minHeight={type === MODAL_TYPE.INVITE ? "calc(20vh + 70px)" : 150}
          >
            <Grid container justifyContent={"flex-end"}>
              <Grid item alignSelf={"center"} width={"calc(100% - 36px)"}>
                <div className={styles["modal-text"]}>
                  {type === MODAL_TYPE.DELETE_USER
                    ? "Felhasználó törlése"
                    : title}
                </div>
              </Grid>
              <Grid item sx={{ mt: "-5px" }}>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={handleClose}
                />
              </Grid>
            </Grid>
            <Separator />
            <div
              className={styles["modal-description"]}
              style={{
                textAlign:
                  type !== MODAL_TYPE.INVITE && type !== MODAL_TYPE.DELETE_USER
                    ? "center"
                    : "start",
              }}
            >
              {type === MODAL_TYPE.DELETE_USER ? (
                <div>
                  <span>{"Biztos hogy törölni kívánod "}</span>
                  <span style={{ fontWeight: 700 }}>{title}</span>
                  <span>
                    {
                      " felhasználót? Amennyiben törlöd nem lesz jogosultsága a továbbiakban belépni Kulcs-Üzlet fiókodba!"
                    }
                  </span>
                </div>
              ) : (
                description
              )}
            </div>
            {type === MODAL_TYPE.INVITE && <InviteUserFields />}
            {type === MODAL_TYPE.EDIT_USER && <EditUserFields user={item} />}
            {error !== null && <div className={styles.error}>{error}</div>}
            {type !== MODAL_TYPE.INVITE && type !== MODAL_TYPE.EDIT_USER && (
              <Box
                pt={2}
                position="absolute"
                bottom="20px"
                width={"calc(100% - 64px)"}
              >
                <Grid container justifyContent={"flex-end"} spacing={2}>
                  {(type === MODAL_TYPE.CONFIRMATION ||
                    type === MODAL_TYPE.DELETE_USER) && (
                    <Grid item>
                      <div style={{ padding: "0 0 0 10px" }}>
                        <StyledButton
                          disabled={isLoading}
                          size={SIZE.SMALL}
                          text={i18n("buttons.notOk")}
                          color={COLOR.LIGHTGRAY}
                          onClick={() => {
                            handleClose();
                            setBackStates();
                          }}
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid item>
                    <StyledButton
                      loading={isLoading}
                      size={SIZE.SMALL}
                      text={
                        type === MODAL_TYPE.DELETE_USER
                          ? "Felhasználó törlése"
                          : i18n("buttons.ok")
                      }
                      color={
                        type === MODAL_TYPE.DELETE_USER
                          ? COLOR.RED
                          : COLOR.DATAPOINT
                      }
                      onClick={
                        type === MODAL_TYPE.CONFIRMATION ||
                        type === MODAL_TYPE.DELETE_USER
                          ? doConfirm
                          : type === MODAL_TYPE.SUCCESS
                          ? () => doConfirm().then(handleClose)
                          : handleClose
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default StyledModal;
