import { useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Navigate } from "react-router-dom";
import { loginRequest } from "../../utils/auth/AuthProvider";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";

const AuthPage = () => {
  const { accounts, inProgress } = useMsal();
  const [showButton, setShowButton] = useState(false);
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    setShowButton(false);
    let action = () => console.log();
    let timer = setTimeout(() => {
      action();
    }, 2000);
    if (inProgress === InteractionStatus.None) {
      if (accounts.length > 0) action = () => <Navigate to={"/selector"} />;
      else action = () => setShowButton(true);
    }

    return () => clearTimeout(timer);
  }, [accounts]);

  useEffect(() => {
    setShowButton(false);
    if (!isAuthenticated) {
      instance
        .ssoSilent(loginRequest)
        .then((res) => {
          console.log(res);
          instance.setActiveAccount(res.account);
        })
        .catch((error: any) => {
          console.log(error);
          if (error instanceof InteractionRequiredAuthError) {
            instance.loginRedirect();
          }
        });
    }
  }, []);

  return <div className="middle-page"></div>;
};

export default AuthPage;
