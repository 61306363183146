import { getInitialsFromName } from '../../utils/helperFunctions'
import styles from './UserInitials.module.scss'

type Props = {
    name: string
}

const UserInitials = ({ name }: Props) => {
    return (<div className={styles["circle"]}>
                <span className={styles["text"]}>{getInitialsFromName(name)}</span>
            </div>)
} 

export default UserInitials