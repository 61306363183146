import {
  TableBody,
  TableCell,
  Box,
  CircularProgress,
  Grid,
} from "@mui/material";
import { ICON_TYPE, COLOR } from "../../../utils/enums/globalEnums";
import { getIcon } from "../../../utils/iconHelper";
import StyledMenu from "../../menu/StyledMenu";
import UserInitials from "../../user-initials/UserInitials";
import { StyledTableCell } from "./StyledTableCell";
import { StyledTableRow } from "./StyledTableRow";
import styles from "../../tabs/TabStyles.module.scss";
import { getMenuItems } from "../user-table-actions/UserTableActions";
import { User } from "../../../UserManagerApi";
import { getEnumTypeText } from "../../../utils/helper-functions/HelperFunctions";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";

type Props = {
  isLoading: boolean;
  users: User[];
};

const StyledTableBody = ({ isLoading, users }: Props) => {
  const { accounts } = useMsal();

  return (
    <TableBody>
      {isLoading ? (
        <StyledTableRow>
          <TableCell colSpan={5}>
            <Box justifyContent={"center"} display={"flex"} mt={5} mb={5}>
              <CircularProgress size={80} />
            </Box>
          </TableCell>
        </StyledTableRow>
      ) : users?.length === 0 ? (
        <StyledTableRow>
          <TableCell colSpan={5}>
            <Box justifyContent={"center"} display={"flex"} mt={5} mb={5}>
              Nincsenek elérhető adatok
            </Box>
          </TableCell>
        </StyledTableRow>
      ) : (
        users.map((user, index) => {
          const menuItems = getMenuItems(user, accounts[0]?.localAccountId);
          return (
            <StyledTableRow
              key={index}
              style={{
                backgroundColor:
                  accounts?.length > 0 &&
                  user?.objectId === accounts[0]?.localAccountId
                    ? "rgba(80, 143, 217, 0.5)"
                    : "",
              }}
            >
              <StyledTableCell align="left">
                <Grid container>
                  <Grid item xs={1}>
                    <UserInitials name={`${user.lastName} ${user.firstName}`} />
                  </Grid>
                  <Grid item xs={3} alignSelf="center" ml={4}>
                    <div className={styles["username-cell"]}>
                      {`${user.lastName} ${user.firstName}`}
                    </div>
                  </Grid>
                </Grid>
              </StyledTableCell>
              <StyledTableCell align="left">{user.email}</StyledTableCell>
              <StyledTableCell align="left">
                {getEnumTypeText(user.role)}
              </StyledTableCell>
              <StyledTableCell align="left">
                {getEnumTypeText(user.invitationStatus)}
              </StyledTableCell>
              <StyledTableCell align="center">
                <StyledMenu
                  child={getIcon(ICON_TYPE.OTHER)}
                  text=""
                  color={COLOR.WHITE}
                  menuItems={menuItems}
                />
              </StyledTableCell>
            </StyledTableRow>
          );
        })
      )}
    </TableBody>
  );
};

export default StyledTableBody;
