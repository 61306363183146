import { COLOR } from "./enums/globalEnums"

export const addOpacity = (colorToChange: COLOR | undefined, opacity: number) => {
    let color = colorToChange
    if(opacity === 0 || opacity === 1)
        return color + ''
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
    // use default primary blue when color isn't hex 
    if (color !== undefined && color.charAt(0) !== '#') 
        color = COLOR.BLUE
    return color + _opacity.toString(16).toUpperCase()
}