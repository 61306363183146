import { LOCAL_STORAGE_KEY } from "../enums/globalEnums";
import { msalInstance, loginRequest } from "./AuthProvider";

export const handleLogin = async () => {
  await msalInstance
    .handleRedirectPromise()
    .then(() => {
      msalInstance.loginRedirect(loginRequest).catch(() => {
        localStorage.clear();
        sessionStorage.clear();
      });
    })
    .catch((error) => {
      if (error.errorCode === "server_error") {
        error.errorMessage.includes("AADB2C99002")
          ? console.log(error.errorMessage)
          : console.log(error.errorMessage);
      } else console.log(error.errorMessage);
    });
};

export const handleLogout = () => {
  const logoutRequest = {
    account: msalInstance.getActiveAccount(),
    postLogoutRedirectUri: "/",
  };
  localStorage.clear();
  msalInstance.logoutRedirect(logoutRequest).catch((err) => {
    console.log(err);
  });
};

export const acquireToken = async () => {
  const request = {
    ...loginRequest,
    account: msalInstance.getAllAccounts()[0],
  };
  return msalInstance
    .acquireTokenSilent(request)
    .then((response) => {
      return response.accessToken;
    })
    .catch(() => {
      msalInstance.acquireTokenRedirect(request).catch((error) => {
        handleLogout();
      });
    });
};
