import React from "react";
import { DeviceContext } from "../../../utils/contexts/globalContexts";
import styles from "./HeaderLayout.module.scss";
import { ReactComponent as KS_LOGO } from "../../../assets/logo/ks-logo.svg";
import { Grid } from "@mui/material";
import { INTERNAL_ROUTE } from "../../../utils/enums/globalEnums";
import { useNavigate } from "react-router-dom";
import i18n from "../../../utils/i18n";

type Props = {
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
};

const HeaderLayout = ({ leftContent, rightContent }: Props) => {
  const { isMobile } = React.useContext(DeviceContext);
  const navigate = useNavigate();
  const goToMainPage = () =>
    window.location.replace(
      process.env.REACT_APP_KULCSUZLET_BASE_URL as string
    );

  return (
    <div className={styles["table-row"]}>
      <Grid
        container
        spacing={0}
        m={"10px"}
        className={styles.header}
        justifyContent={"space-between"}
      >
        <Grid item xs={false}>
          <img
            onClick={goToMainPage}
            style={{ cursor: "pointer" }}
            src={require("../../../assets/logo/ks-logo.svg")?.default}
            alt=""
          />
        </Grid>
        <Grid item xs={false} maxWidth={"70vw"} maxHeight={"90px"}>
          {rightContent}
        </Grid>
      </Grid>
    </div>
  );
};

export default HeaderLayout;
