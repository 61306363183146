import styled from "@emotion/styled";
import { TableCell, tableCellClasses } from "@mui/material";

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontFamily: '"Roboto", normal',
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#525252",
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: '"Roboto", normal',
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "18px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));
