import { Fragment, useContext, useState } from "react";
import styles from "./CompanySelector.module.scss";
import ClickAwayListener from "react-click-away-listener";
import { SelectedCompanyContext } from "../../utils/contexts/globalContexts";
import { B2CCompanyData } from "../../UserManagerApi";
import { getEnumTypeText } from "../../utils/helper-functions/HelperFunctions";

type Props = {
  companies: B2CCompanyData[];
  changeSelectedCompany: (companyId: string) => void;
  text?: string;
};

const CompanySelector = ({ changeSelectedCompany, companies, text }: Props) => {
  const { selectedCompany, setSelectedCompany } = useContext(
    SelectedCompanyContext
  );
  const [isFocusedCompany, setIsFocusedCompany] = useState(false);
  const parentwidthCompany =
    document.getElementById("company-selector-company")?.offsetWidth ?? 0;

  const handleClickCompany = (dbGuid: string) => {
    changeSelectedCompany(dbGuid ?? "");
    setIsFocusedCompany(false);
  };

  const handleClickAwayCompanyEvent = () => setIsFocusedCompany(false);

  return (
    <Fragment>
      <div
        id="company-selector-company"
        className={styles.select}
        onClick={() => setIsFocusedCompany(!isFocusedCompany)}
        style={{
          padding: "3px 32px 3px 16px",
          height: "30px",
          fontSize: "21px",
          backgroundColor: "white",
        }}
      >
        {selectedCompany?.companyName ?? text ?? "-"}
      </div>
      {isFocusedCompany && companies.length > 0 && (
        <ClickAwayListener onClickAway={handleClickAwayCompanyEvent}>
          <ul
            id="company-ul"
            className={styles["option-container"]}
            style={{
              width: parentwidthCompany > 0 ? parentwidthCompany - 60 : 0,
            }}
          >
            {companies?.map((company: B2CCompanyData, index: number) => (
              <li
                className={styles.item}
                key={index}
                onClick={() => handleClickCompany(company?.guid ?? "")}
              >
                <div style={{ fontSize: "15px" }}>
                  <b>
                    {company?.companyName ?? (
                      <i style={{ fontSize: "9px" }}>
                        {"Nincs megjeleníthető cégnév"}
                      </i>
                    )}
                  </b>
                </div>
                <span style={{ fontSize: "13px" }}>
                  {getEnumTypeText(company?.role) ?? ""}
                </span>
              </li>
            ))}
          </ul>
        </ClickAwayListener>
      )}
    </Fragment>
  );
};

export default CompanySelector;
