import get from 'lodash/get'

type TranslationMap = {
  language: string
  texts: Object
}

enum LANGUAGE {
  HU = 'hu',
  EN = 'en',
  DEV = 'dev',
}

const availableTranslations: TranslationMap[] = [
  {
    language: LANGUAGE.HU,
    texts: require('./translations/hu.json'),
  },
  {
    language: LANGUAGE.DEV,
    texts: {},
  },
]

const defaultLanguage = LANGUAGE.HU

let language = defaultLanguage

export const currentLanguage = () => {
  return language
}

const getTranslation = (key: string, args: Map<string, string> = new Map()) => {
  if (language === LANGUAGE.DEV) {
    return key
  }
  try {
    let text =
      get(
        availableTranslations.find((translationmap) => translationmap.language === language)?.texts,
        key
      ) ||
      get(
        availableTranslations?.find((translationmap) => translationmap.language === 'hu')?.texts,
        key
      )
    args.forEach((value: string, key: string) => {
      const regexp = new RegExp(`{{${key}}}`)
      text = text.replace(regexp, value)
    })

    if (text) {
      return text
    }
  } catch (error) {
    console.log(error)
  }

  return key
}

const i18n = getTranslation
export default i18n
