import { Fragment } from "react";
import styles from "./StyledInput.module.scss";
import { v4 as uuidv4 } from "uuid";
import {
  InputBase,
  IconButton,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { Box } from "@mui/system";
import { getEnumTypeText } from "../../utils/helper-functions/HelperFunctions";

export enum INPUT_TYPE {
  TEXT = "text",
  SELECT = "select",
  FILE = "file",
  NUMBER = "number",
}

type Props = {
  type: INPUT_TYPE;
  name: string;
  placeholder?: string;
  value?: any;
  changed?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  options?: any[];
  hasEmptyOption?: boolean;
  isExpanded?: boolean;
  max?: number;
  isDisabled?: boolean;
  min?: number;
  withSearchIcon?: boolean;
  iconClick?: () => void;
  searchFocus?: boolean;
  setSearchFocus?: React.Dispatch<React.SetStateAction<boolean>>;
};
const StyledInput = ({
  type,
  name,
  placeholder,
  value,
  changed,
  onKeyDown,
  options,
  hasEmptyOption,
  isExpanded,
  max,
  isDisabled,
  min,
  withSearchIcon,
  iconClick,
  searchFocus,
  setSearchFocus,
}: Props) => {
  // const { isMobile } = useContext(DeviceContext)
  const id = uuidv4() + "inputfield";

  const getClasses = () =>
    `${styles.input}
		${styles[type]} 
        ${(!changed || isDisabled) && styles.disabled} 
		${isExpanded && styles.expanded}`;

  if (options && !options.length) options = Object.values(options);

  const getInputType = () => (type === INPUT_TYPE.NUMBER ? "number" : "text");

  const handleSearchClick = () => {
    // if(!isMobile) {
    // 	iconClick && iconClick()
    // }
    // else
    if (searchFocus) {
      setSearchFocus && setSearchFocus(false);
      iconClick && iconClick();
    } else {
      setSearchFocus && setSearchFocus(true);
    }
  };

  const Text = withSearchIcon ? (
    <Box
      className={`${
        searchFocus &&
        //  && isMobile
        styles["reveal-anim"]
      } ${
        !searchFocus &&
        //  && isMobile
        styles["veil-anim"]
      } ${styles["search-container"]}`}
      sx={{
        display: "flex",
        alignItems: "center",
        ml: searchFocus ? "auto" : 3,
        minWidth:
          // isMobile ? '67px' :
          "auto",
        maxWidth:
          // isMobile &&
          !searchFocus ? "67px" : "auto",
      }}
    >
      {
        // ((searchFocus && isMobile) || !isMobile)
        searchFocus && (
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            onChange={changed}
            onKeyDown={onKeyDown}
          />
        )
      }
      <IconButton
        sx={{ ml: 1 }}
        aria-label="search"
        onClick={handleSearchClick}
      >
        {/* {getIcon(ICON_TYPE.SEARCH)} */}
      </IconButton>
    </Box>
  ) : (
    <Fragment>
      <input
        id={id}
        maxLength={max}
        className={getClasses()}
        type={getInputType()}
        min={min}
        name={name}
        placeholder={placeholder ? placeholder + "..." : ""}
        onChange={changed}
        onKeyDown={onKeyDown}
        value={value ?? ""}
        readOnly={isDisabled || !changed}
      />
    </Fragment>
  );

  const SelectElement = (
    <FormControl sx={{ minWidth: 120, width: "100%" }}>
      <Select
        size="small"
        name={name}
        value={value ?? (options ? options[0] : "")}
        onChange={changed}
        displayEmpty
        id="my-select"
        MenuProps={{
          PaperProps: {
            sx: {
              marginTop: "-7px",
              paddingTop: 0,
              fontFamily: "Roboto",
              "& .MuiMenuItem-root.Mui-selected": {
                backgroundColor: "#3D79BD",
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: "#aec9e8",
              },
              "& .MuiMenuItem-root.Mui-selected:hover": {
                backgroundColor: "#a3b4c7",
              },
              "&.MuiOutlinedInput-input:hover": {
                border: "2px solid #7ea5d0",
              },
              "&.MuiOutlinedInput-input:active": {
                border: "2px solid #7ea5d0",
              },
              ul: {
                padding: 0,
              },
            },
          },
        }}
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          "& #my-select": {
            fontSize: "16px",
            fontWeight: 300,
            fontFamily: '"Roboto", normal',
          },
          height: "28.5px",
          ".MuiOutlinedInput-input": {
            padding: "8.5px 14px 8.5px 6px",
            fontSize: "13px",
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#ACBBCD",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ACBBCD",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#c5defc",
          },
        }}
      >
        {hasEmptyOption && (
          <MenuItem value={""}>
            <em>Üres</em>
          </MenuItem>
        )}
        {options &&
          options.map((option, index) => (
            <MenuItem
              style={{
                fontSize: "14px",
                paddingTop: "2px",
                paddingBottom: "2px",
                paddingRight: "6px",
                paddingLeft: "6px",
                minHeight: "0px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 0.6)",
              }}
              key={index}
              value={option}
            >
              {getEnumTypeText(option)}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );

  const getInputArea = () => {
    switch (type) {
      case INPUT_TYPE.TEXT:
      case INPUT_TYPE.NUMBER:
        return Text;
      case INPUT_TYPE.SELECT:
        return SelectElement;
      default:
        return <></>;
    }
  };

  return <Fragment>{getInputArea()}</Fragment>;
};

export default StyledInput;
